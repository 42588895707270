"use client"
import { AuthClientPlugin } from "@blitzjs/auth"
import { setupBlitzClient } from "@blitzjs/next"
import { BlitzRpcPlugin } from "@blitzjs/rpc"
import { FubAuthClientPlugin } from "app/fubAuth/clientPlugin"

export const { withBlitz, BlitzProvider } = setupBlitzClient({
  plugins: [
    AuthClientPlugin({
      cookiePrefix: "BrokerBlocks",
    }),
    FubAuthClientPlugin({}),
    BlitzRpcPlugin({}),
  ],
})
