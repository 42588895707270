import { useContext, createContext, useRef, useEffect, useState } from "react"

export interface ModalState {
  counter: number
  instances: string[]
}

export enum ModalActionType {
  REGISTER_MODAL = "REGISTER_MODAL",
  DEREGISTER_MODAL = "DEREGISTER_MODAL",
}

export type ModalAction =
  | { type: ModalActionType.REGISTER_MODAL }
  | { type: ModalActionType.DEREGISTER_MODAL; payload: string }

export type ModalMethods = {
  registerModal: () => string
  deregisterModal: (instanceId: string) => void
}

export const ModalContext = createContext<ModalState | undefined>(undefined)

export const ModalMethodsContext = createContext<ModalMethods | undefined>(undefined)

export function useModal() {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider")
  }
  return context
}

export function useModalActions() {
  const context = useContext(ModalMethodsContext)
  if (context === undefined) {
    throw new Error("useModalActions must be used within a ModalProvider")
  }
  return context
}

export function useModalInstance() {
  const { registerModal, deregisterModal } = useModalActions()
  const [newInstanceId, setNewInstanceId] = useState<string | null>(null)
  const newInstanceIdRef = useRef<string | null>(null)

  useEffect(() => {
    newInstanceIdRef.current = newInstanceId
  }, [newInstanceId])

  useEffect(() => {
    const newInstanceId = registerModal()
    setNewInstanceId(newInstanceId)

    return () => {
      if (newInstanceIdRef.current) {
        deregisterModal(newInstanceIdRef.current)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return newInstanceId
}
