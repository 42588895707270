import { EmptyPublicData, PublicData } from "@blitzjs/auth"
import { MicroObservable } from "@Utils/microObservable"
import { parse } from "querystring"
import { isFubSubdomain } from "./commonUtils"

export function getIsFubEmbeddedApp() {
  return typeof window !== "undefined" && isFubSubdomain(window.location.hostname)
}

export const emptyPublicData: EmptyPublicData = { userId: null, role: null }

const SESSION_STORAGE_PREFIX = "FubWidget"
const SESSION_STORAGE_CONTEXT_KEY = `${SESSION_STORAGE_PREFIX}_sContext`
const SESSION_STORAGE_SIGNATURE_KEY = `${SESSION_STORAGE_PREFIX}_sSignature`
const SESSION_STORAGE_PUBLIC_DATA_KEY = `${SESSION_STORAGE_PREFIX}_sPublicData`

class ContextAndSignatureStore {
  getData() {
    try {
      const context = window.sessionStorage.getItem(SESSION_STORAGE_CONTEXT_KEY) || ""
      const signature = window.sessionStorage.getItem(SESSION_STORAGE_SIGNATURE_KEY) || ""
      return { context, signature }
    } catch (err) {
      console.error("SessionStorage is not available", err)
      return { context: "", signature: "" }
    }
  }

  setData(context: string | null, signature: string | null) {
    if (context && signature) {
      try {
        window.sessionStorage.setItem(SESSION_STORAGE_CONTEXT_KEY, context)
        window.sessionStorage.setItem(SESSION_STORAGE_SIGNATURE_KEY, signature)
      } catch (err) {
        console.error("SessionStorage is not available", err)
      }
    }
  }
}

export function getContextAndSignatureStore() {
  if (!(window as any).__contextAndSignatureStore) {
    ;(window as any).__contextAndSignatureStore = new ContextAndSignatureStore()
  }
  return (window as any).__contextAndSignatureStore
}

export function getContextAndSignature() {
  if (typeof window === "undefined") {
    return {
      context: "",
      signature: "",
    }
  }

  const queryParams = parse(window.location.search.slice(1))
  let signature = (queryParams.signature as string) || ""
  let context = (queryParams.context as string) || ""

  if (!signature || !context) {
    // Try to get from session storage
    const sessionData = getContextAndSignatureStore().getData()
    signature = sessionData.signature
    context = sessionData.context
  }

  return { context, signature }
}

function parsePublicData(data: string) {
  try {
    return JSON.parse(data) as PublicData
  } catch (e) {
    return emptyPublicData
  }
}

/**
 * Mock for Blitz PublicData Store (useSession)
 */
class PublicDataStore {
  readonly observable = MicroObservable<PublicData | EmptyPublicData>()

  updateState(value?: PublicData | EmptyPublicData) {
    this.observable.next(value ?? this.getData())
  }

  clear() {
    try {
      sessionStorage.removeItem(SESSION_STORAGE_PUBLIC_DATA_KEY)
    } catch (err) {
      console.error("SessionStorage is not available", err)
    }
    this.updateState(emptyPublicData)
  }

  getData() {
    const publicDataJSON = this.getPublicData()
    if (!publicDataJSON) {
      return emptyPublicData
    }
    const publicDataObj = parsePublicData(publicDataJSON)
    return publicDataObj
  }

  private getPublicData() {
    try {
      return sessionStorage.getItem(SESSION_STORAGE_PUBLIC_DATA_KEY)
    } catch (err) {
      console.error("SessionStorage is not available", err)
      return undefined
    }
  }
}

export function getPublicDataStore(): PublicDataStore {
  if (!(window as any).__publicDataStore) {
    ;(window as any).__publicDataStore = new PublicDataStore()
  }
  return (window as any).__publicDataStore
}

export function applyFubClientSession() {
  const isFubEmbeddedApp = getIsFubEmbeddedApp()
  if (!isFubEmbeddedApp) {
    return
  }
  if (typeof window !== "undefined") {
    getPublicDataStore()
  }
}

export function applyFubFetchInterceptor() {
  const isFubEmbeddedApp = getIsFubEmbeddedApp()
  if (!isFubEmbeddedApp) {
    return
  }

  const originalFetch = window.fetch

  window.fetch = async (input, init = {}) => {
    let url = ""
    if (typeof input === "string") {
      url = input
    } else if (input instanceof Request) {
      url = input.url
    } else if (input instanceof URL) {
      url = input.toString()
    }

    if (url.includes("/_next/data")) {
      const { context, signature } = getContextAndSignature()
      if (context && signature) {
        init.headers = {
          ...init.headers,
          "x-fub-context": context,
          "x-fub-signature": signature,
        }
      }
    }

    return originalFetch(input, init)
  }
}
