import { useCallback, useEffect, useMemo, useReducer, useRef } from "react"
import {
  ModalState,
  ModalAction,
  ModalActionType,
  ModalMethodsContext,
  ModalContext,
} from "./ModalContext"

const initialState: ModalState = {
  counter: 0,
  instances: [],
}

const reducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case ModalActionType.REGISTER_MODAL:
      return {
        ...state,
        instances: [...state.instances, `modal-${state.counter + 1}`],
        counter: state.counter + 1,
      }
    case ModalActionType.DEREGISTER_MODAL:
      return {
        ...state,
        instances: state.instances.filter((instance) => instance !== action.payload),
      }
  }
}

const ModalPortalProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const counterRef = useRef(state.counter)

  const registerModal = useCallback(() => {
    const newInstanceId = `modal-${counterRef.current + 1}`
    counterRef.current = counterRef.current + 1
    dispatch({ type: ModalActionType.REGISTER_MODAL })
    return newInstanceId
  }, [])

  const deregisterModal = useCallback((instanceId: string) => {
    counterRef.current = counterRef.current - 1
    dispatch({ type: ModalActionType.DEREGISTER_MODAL, payload: instanceId })
  }, [])

  const modalActions = useMemo(
    () => ({
      registerModal,
      deregisterModal,
    }),
    [registerModal, deregisterModal]
  )

  return (
    <ModalContext.Provider value={state}>
      <ModalMethodsContext.Provider value={modalActions}>{children}</ModalMethodsContext.Provider>
    </ModalContext.Provider>
  )
}

export default ModalPortalProvider
