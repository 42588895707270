import { useCallback } from "react"
import toast from "@Components/ui/Toast"
import { SocketEventStatus, SocketEventTypes } from "./types"
import { useSocketEvent } from "./useSocketEvent"

const toastSocketGroupKey = "socket-message"

const SocketMessageEventListener: React.FC = () => {
  const handleMessageDisplay = useCallback(
    ({ socketEventStatus, message }: Record<string, string>) => {
      if (socketEventStatus === SocketEventStatus.CloseMessage) {
        toast.remove({ groupKey: toastSocketGroupKey })
        return
      }

      if (!message) {
        return
      }

      toast.remove({ groupKey: toastSocketGroupKey })
      if (socketEventStatus === SocketEventStatus.Pending) {
        toast.info(message, { duration: 0, groupKey: toastSocketGroupKey })
      } else if (socketEventStatus === SocketEventStatus.Success) {
        toast.success(message, { groupKey: toastSocketGroupKey })
      } else if (socketEventStatus === SocketEventStatus.Error) {
        toast.error(message, { groupKey: toastSocketGroupKey })
      }
    },
    []
  )

  useSocketEvent(SocketEventTypes.Message, handleMessageDisplay)

  return null
}

export default SocketMessageEventListener
