import { createContext, useContext } from "react"
import type { Toast } from "./types"

export type ToastContextType = Toast[]

export const ToastContext = createContext<ToastContextType | undefined>(undefined)

export function useToasts(): ToastContextType {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error("ToastHost must be used within a ToastProvider")
  }
  return context
}
