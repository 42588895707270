import {
  ConsentGivenBy,
  Customer,
  CustomerFileType,
  File,
  FollowUpBossApiKey,
  Group,
  Nba,
  Opportunity,
  OpportunityAdditionalContacts,
  ReviewLinks,
  ReviewSource,
  SocialMediaTypes,
  Touchpoint,
  TouchpointAccessHistory,
  TouchpointType,
  User,
} from "db"

export interface FileData {
  url: string | null
  file?: File | null
}

export enum TouchpointViewMode {
  Mobile = "mobile",
  Desktop = "desktop",
  Thumbnail = "thumbnail",
}

export enum DOMElementIds {
  MainLayout = "bb-main-layout",
  PreloadAssetsContent = "bb-preload-assets-content",
  PreloadAssetsLoadingBar = "bb-preload-assets-loading-bar",
  EditorContent = "bb-editor-content",
  DraggableArea = "bb-draggable-area",
  CardPreviewInEmail = "bb-card-preview-in-email",
  EmailContentToEdit = "bb-email-content-to-edit",
  FullScreenLoading = "bb-full-screen-loading",
  FullScreenLoadingDrawer = "bb-full-screen-loading-drawer",
  DashboardHintsImport = "bb-dashboard-hints-import",
  DashboardHintsNewLead = "bb-dashboard-hints-quick-add",
  LoadingStatus = "bb-loading-status",
  FullScreenPreviewContainer = "bb-full-screen-preview-container",
  IntakeFlowForm = "bb-intake-flow-form",
  NbaActionListContainer = "bb-nba-action-list-container",
}

export enum QueryParams {
  ForGenerateScreenshot = "for_generate_screenshot",
  NbaNotificationId = "nbaNid",
  OpportunityId = "opportunityId",
  NotificationId = "nid",
  TokenId = "tid",
}

export enum BooleanStrings {
  True = "true",
  False = "false",
}

export enum HttpMethods {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
  HEAD = "HEAD",
  PUT = "PUT",
}

export type MetaTag = {
  content: string
  name?: string
  property?: string
}

export type CustomerProfileFiles = {
  profileImageFile?: File | null
  companyLogoFile?: File | null
  profileImageNoBackgroundFile?: File | null
}

export type UserExtended = User & {
  emailConsentGiven: boolean
  impersonatedDelegateNameAndSurname: string
}

export enum Providers {
  NonSSO = "Non-SSO",
  LinkedIn = "LinkedIn",
  Google = "Google",
  Apple = "Apple",
}

export type CustomerExtended = Customer &
  CustomerProfileFiles & {
    customerFilesByType: Record<CustomerFileType, File[]>
    presentationAutoSendEnabled: boolean
    ReviewLinks: ReviewLinks | null
    FollowUpBossApiKey: FollowUpBossApiKey | null
  }

export type GroupExtended = Group & {
  companyLogoFile: File | null
  disabledBuyerTouchpointsByName: Record<TouchpointType, boolean>
  disabledSellerTouchpointsByName: Record<TouchpointType, boolean>
  disabledFeaturesByName: Record<ApplicationFeatures, boolean>
  allAvailableTouchpointTypes: TouchpointType[]
  ReviewLinks: ReviewLinks | null
  FollowUpBossApiKey: FollowUpBossApiKey | null
}

export type TouchpointExtended = Pick<
  Touchpoint,
  "id" | "sentAt" | "type" | "opportunityType" | "status"
> & {
  accessHistory?: Pick<TouchpointAccessHistory, "openedAt">[]
}

export type OpportunityExtended = Opportunity & {
  zillowListingMainImage?: File | null
  opportunityAdditionalContacts?: OpportunityAdditionalContacts[]
  nba?: Nba | null
  hadAnyNbaBefore?: boolean
  emailConsentGiven?: boolean
  smsConsentGiven?: boolean
  smsConsentGivenBy?: ConsentGivenBy
  smsContactEstablished?: boolean
  touchpoints?: TouchpointExtended[]
  sectionKey?: string
}

export type TouchpointAccessHistoryExtended = TouchpointAccessHistory & {
  touchpoint: Touchpoint & { opportunity: Opportunity }
  openedByAdditionalContact: OpportunityAdditionalContacts | null
}

export type SignatureAttributes = Pick<
  CustomerExtended,
  | "signatureType"
  | "signatureFont"
  | "signatureText"
  | "signatureUseInitials"
  | "signatureUseNoBackground"
> & {
  signatureDrawnFile: File | null
  signatureUploadedFile: File | null
  signatureUploadedNoBackgroundFile: File | null
}

export type SocialMedia = {
  [SocialMediaTypes.FACEBOOK]?: string | null
  [SocialMediaTypes.INSTAGRAM]?: string | null
  [SocialMediaTypes.TWITTER]?: string | null
  [SocialMediaTypes.LINKEDIN]?: string | null
  [SocialMediaTypes.ZILLOW]?: string | null
}

export enum NotificationConfirmInputs {
  NotifyAppointmentCancelledSmsField = "notifyAppointmentCancelledSmsField",
  NotifyAppointmentReminderSmsField = "notifyAppointmentReminderSmsField",
  NotifyAppointmentReminderAiRapportSmsField = "notifyAppointmentReminderAiRapportSmsField",
  NotifyAppointmentReminderEmailField = "notifyAppointmentReminderEmailField",
  NotifyBrokerIntakeFormSmsField = "notifyBrokerIntakeFormSmsField",
  NotifyBrokerIntakeFormEmailField = "notifyBrokerIntakeFormEmailField",
  NotifyBrokerNbaReminderSmsField = "notifyBrokerNbaReminderSmsField",
  NotifyBrokerNbaReminderEmailField = "notifyBrokerNbaReminderEmailField",
  NotifyBrokerClosingDateSmsField = "notifyBrokerClosingDateSmsField",
  NotifyBrokerNewNbaCrmWritebackField = "notifyBrokerNewNbaCrmWritebackField",
  NotifyBrokerDailyIntakeFormSmsField = "notifyBrokerDailyIntakeFormSmsField",
  NotifyBrokerDailyIntakeFormEmailField = "notifyBrokerDailyIntakeFormEmailField",
}

export enum CourtesyTitleValues {
  Formal = "formal",
  FirstName = "firstName",
}

export type YearsAsRealtorLabel =
  | "1 year"
  | "2 years"
  | "3 years"
  | "4 years"
  | "5 years"
  | "6-10 years"
  | "11-20 years"
  | "21-30 years"
  | "30+ years"

export type CustomerYearsAsRealtorOption = {
  label: YearsAsRealtorLabel
  value: YearsAsRealtorLabel
}

export type LatestClosingsLabel =
  | "1 closing"
  | "2 closings"
  | "3 closings"
  | "4 closings"
  | "5 closings"
  | "6-10 closings"
  | "11-20 closings"
  | "21-30 closings"
  | "30+ closings"

export type CustomerLatestClosingsOption = {
  label: LatestClosingsLabel
  value: number
}

export enum DaysOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

export enum ApplicationFeatures {
  IntakeFlow = "IntakeFlow",
}

export interface ReviewItem {
  text: string
  reviewersName: string
  rating?: number
  source: ReviewSource
}
