import socketIOClient, { Socket } from "socket.io-client"
import { createContext, useContext, useState, useEffect, useRef } from "react"
import { invoke } from "@blitzjs/rpc"
import registerSocketId from "app/auth/mutations/register-socket-id"
import { logError } from "integrations/sentry"
import { useSession } from "@blitzjs/auth"

export const SocketContext = createContext<Socket | null>(null)

function useSocketProvider() {
  const [socket, setSocket] = useState<Socket | null>(null)
  const socketRef = useRef<Socket | null>(null)
  const { userId } = useSession()

  useEffect(() => {
    if (userId) {
      const socket = socketIOClient(process.env.NEXT_PUBLIC_BASE_URL!, {
        transports: ["websocket"], // remove this once sticky sessions are enabled in AWS
      })

      socketRef.current = socket

      socket.on("connect", async () => {
        try {
          await invoke(registerSocketId, { socketId: socket.id })
        } catch (error) {
          logError(error, "Failed to register socket in session")
        }

        setSocket(socket)
      })
    }

    return () => {
      socketRef.current?.close()
    }
  }, [userId])

  return socket
}

export function useSocket() {
  const context = useContext(SocketContext)
  return context
}

type Props = React.PropsWithChildren<{}>

const SocketClientContextProvider = ({ children }: Props) => {
  const contextValue = useSocketProvider()

  return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>
}

const SocketServerContextProvider = ({ children }: Props) => {
  return <SocketContext.Provider value={null}>{children}</SocketContext.Provider>
}

export const SocketContextProvider = ({ children }: Props) => {
  return typeof window !== "undefined" ? (
    <SocketClientContextProvider>{children}</SocketClientContextProvider>
  ) : (
    <SocketServerContextProvider>{children}</SocketServerContextProvider>
  )
}
