import { cn } from "@Utils/theme"
import { MODAL_HOST_ID } from "./constants"
import { useModal } from "./ModalContext"

const ModalPortalHost = () => {
  const { instances } = useModal()

  return (
    <div className={cn("fixed inset-0 z-[9999] overflow-y-auto", !instances.length && "hidden")}>
      <div id={MODAL_HOST_ID} className="fixed inset-0 w-full h-full" />
    </div>
  )
}

export default ModalPortalHost
