import { useEffect, useRef } from "react"
import { useSocket } from "./SocketContext"
import { SocketEventTypes } from "./types"

export const useSocketEvent = (
  eventType: SocketEventTypes,
  callback: (data: Record<string, string>) => void
) => {
  const callbackRef = useRef(callback)
  const socket = useSocket()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (!socket) {
      return
    }

    const connectEvent = async () => {
      socket.on(eventType, (data: Record<string, string>) => {
        callbackRef.current(data)
      })
    }

    connectEvent()

    return () => {
      socket.off(eventType, callbackRef.current)
    }
  }, [eventType, socket])
}
