import { getAntiCSRFToken } from "@blitzjs/auth"
import { HttpMethods } from "@Types/common"
import toast from "@Components/ui/Toast"

export const reportError = (userError: string, errorDetails: string) => {
  console.log(userError, errorDetails)
  toast.error(userError)
}

export const reportErrorIfPresent = (apiResponse: any, userError: string) => {
  if ("error" in apiResponse) {
    reportError(userError, apiResponse.error?.data?.error?.message)
  }
}

export const createApiRequestBody = (
  url: string,
  method: HttpMethods = HttpMethods.POST,
  data: Record<string, any> = {}
) => ({
  url,
  method,
  body: JSON.stringify(data),
  headers: { "anti-csrf": getAntiCSRFToken() },
})
