import { Toast } from "./types"

let counter = 0
let hostMounted = false

export function setHostMounted(mounted: boolean) {
  hostMounted = mounted
}

export function isHostMounted() {
  return hostMounted
}

const initialToasts = new Map<string, Toast>()

export function getInitialToasts() {
  return initialToasts
}

export const emitter = new EventTarget()

export function showToast({
  dismissible = true,
  duration = 4000,
  ...input
}: Omit<Toast, "id">): string {
  counter++
  const id = `${counter}+${new Date().getTime()}`
  const toast = { dismissible, duration, ...input, id }
  if (!hostMounted) {
    initialToasts.set(id, toast)
  } else {
    emitter.dispatchEvent(new CustomEvent("toast", { detail: toast }))
  }
  return id
}

export function removeToast<T>({ id, groupKey }: { id?: string; groupKey?: string }) {
  if (!hostMounted) {
    if (id) {
      initialToasts.delete(id)
    } else if (groupKey) {
      for (const [toastId, toast] of initialToasts.entries()) {
        if (toast.groupKey === groupKey) {
          initialToasts.delete(toastId)
        }
      }
    }
  } else {
    emitter.dispatchEvent(new CustomEvent("remove-toast", { detail: { id, groupKey } }))
  }
}

export const toastApi = {
  show: showToast,
  remove: removeToast,
  success: (message: Toast["message"], rest?: Omit<Toast, "id" | "message" | "type">) =>
    showToast({ message, type: "success", ...rest }),
  info: (message: Toast["message"], rest?: Omit<Toast, "id" | "message" | "type">) =>
    showToast({ message, type: "info", ...rest }),
  loading: (message: Toast["message"], rest?: Omit<Toast, "id" | "message" | "type">) =>
    showToast({ message, type: "loading", duration: 0, dismissible: false, ...rest }),
  error: (message: Toast["message"], rest?: Omit<Toast, "id" | "message" | "type">) =>
    showToast({ message, type: "error", ...rest }),
}
