import { createClientPlugin } from "blitz"
import { getContextAndSignature, getIsFubEmbeddedApp } from "./clientUtils"

export interface FubAuthClientPluginOptions {}

export const FubAuthClientPlugin = createClientPlugin(
  (options: FubAuthClientPluginOptions = {}) => {
    return {
      events: {
        onRpcError: async (error) => {
          if (error.name === "AuthenticationError") {
            // what to do when the user is not authenticated?
          }
        },
        onSessionCreated: async () => {
          // triggered when any response returns header `"session-created": "true"`
        },
      },
      middleware: {
        beforeHttpRequest(req) {
          const isFubEmbeddedApp = getIsFubEmbeddedApp()
          if (!isFubEmbeddedApp) {
            return req
          }

          const { context, signature } = getContextAndSignature()
          if (context && signature) {
            req.headers = {
              ...req.headers,
              "x-fub-context": context,
              "x-fub-signature": signature,
            }
          }

          return req
        },
      },
      exports: () => ({
        __NOTHING_TO_EXPORT__: null,
      }),
    }
  }
)
